import React, {useContext} from "react"
import UserContext from "../context/user"
import Header from '../components/header'
import Section from '../components/section'
import {ReportReviewForm} from "../components/report-review"

const Report = ({ pageContext: { employee, manager } }) => {
  const user = useContext(UserContext)

  if(user.username === employee.id) {
    // navigate('/')
    return null
  } else {
    return (
      <Section>
        <Header/>
        <br/>
        <div style={{color: 'black', fontSize: '24px', fontWeight: 'bold'}}>
        Report Review for {employee.name}
        </div>
        <br/>

        <ReportReviewForm
          report={employee}
        />
      </Section>
    )
  }
}

export default Report